import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { CustomToast } from "../ReusableComponents/CustomToast";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const SignUp = (props: any) => {
  let navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = (data: any) => {
    setSubmitted(true);
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        if (user.email) {
          const userRef = doc(db, 'users', user?.email);
          setDoc(userRef,
            {
              businessName: data.business_name,
              name: data.name,
              phoneNumber: data.phone_number,
              stage: "prestart",
              lastKnownTriage: 0,
              triageNumber: 0
            }, { merge: true });
          CustomToast('success', `Success! ${user.email} has been signed up successfully!`);
          navigate("/prestart");
        }
      })
      .catch((error) => {
        setSubmitted(false);
        if (error.code === 400) {
          CustomToast('error', `The email already exists! Please Login`);
        }
        else {
          CustomToast('error', `Could not sign you up, Please try again later`);
        }

      });
  };
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <div className="card">
      <div className="card-body text-center px-4 py-5 px-md-5">
        <p className="fs-3 fw-bold text-primary card-text mb-2" id="title-color">Sign Up</p>
        <div>
          <form className="p-3 p-xl-4" method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="text-start mb-3 has-validation">
              <label className="form-label">Enter Your Name:</label>
              <input
                className={`form-control ${(errors.name?.type === 'required' ? "is-invalid" : "")}`}
                type="text"
                id="name-1"
                placeholder="Please enter your Full Name"
                {...register("name", { required: true })}
              />
              <div className="invalid-feedback">
                {errors.name?.type === 'required' && "Full Name is required"}
              </div>
            </div>
            <div className="text-start mb-3">
              <label className="form-label">Enter Your Business Name:</label>
              <input
                className={`form-control ${(errors.business_name?.type === 'required' ? "is-invalid" : "")}`}
                type="text"
                id="name-1"
                placeholder="Business Name"
                {...register("business_name", { required: true })}
              />
              <div className="invalid-feedback">
                {errors.business_name?.type === 'required' && "Business Name is required"}
              </div>
            </div>
            <div className="text-start mb-3">
              <label className="form-label">Enter Your Email Address:</label>
              <input
                className={`form-control ${(errors.email?.type === 'required' ? "is-invalid" : "")}`}
                type="email"
                id="email-1"
                placeholder="Enter your Email address"
                {...register("email", { required: true })}
              />
              <div className="invalid-feedback">
                {errors.email?.type === 'required' && "Email is required"}
              </div>
            </div>
            <div className="text-start mb-3">
              <label className="form-label">Enter Your Phone Number:</label>
              <input
                className={`form-control ${(errors.phone_number?.type === 'required' ? "is-invalid" : "")}`}
                type="tel"
                placeholder="Enter your Phone Nummber"
                {...register("phone_number", { required: true })}
              />
              <div className="invalid-feedback">
                {errors.phone_number?.type === 'required' && "Phone Number is required"}
              </div>
            </div>
            <div className="text-start mb-3">
              <label className="form-label">Enter A New Password:</label>
              <input
                className={`form-control ${(errors.password ? "is-invalid" : "")}`}
                type="password"
                id="email-2"
                {...register("password", { minLength: 6, required: true })}
                placeholder="Enter your Password"
              />
              <div className="invalid-feedback">
                {errors.password?.type === "minLength" && "The Password cannot be shorter than 6 Characters"}
              </div>
            </div>
            <div className="text-start mb-3">
              <label className="form-label">Re-Enter your Password:</label>
              <input
                className={`form-control ${(errors.confirm_password ? "is-invalid" : "")}`}
                type="password"
                id="password_confirmation"
                {...register("confirm_password", {
                  minLength: 6, required: true, validate: (val: string) => {
                    if (watch('password') !== val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
                placeholder="Re-enter Password"
              />
              <div className="invalid-feedback">
                {errors.confirm_password?.type === "minLength" && "The Password cannot be shorter than 6 Characters"}
                {errors.confirm_password?.message}
              </div>
            </div>
            <div className="mb-3" />
            <div>
              <button
                className="btn btn-primary shadow d-block w-100 text-center mx-auto"
                type="submit"
              >
                {submitted ? <TailSpin wrapperStyle={{ display: "block" }} ariaLabel="loading-indicator" color="white" height={25} /> : "Sign up and Continue"}
              </button>
            </div>
          </form>
        </div>
        <hr />
        I already have an account
        <button
          className="btn mt-3 btn-secondary shadow d-block w-100 text-center mx-auto"
          type="button" onClick={() => navigate('/signin')}
        > Log in</button>
      </div>
    </div >
  );
}

export default SignUp
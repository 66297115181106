import { useCallback, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FinanceAnime from "../assets/img/keyanalysis.gif";
import { CustomToast } from "../ReusableComponents/CustomToast";
import CallToAction from "../ReusableComponents/CallToAction";

const KeyRatioAnalysisResults = (props: any) => {
  let navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const user: any = auth.currentUser;
  const { reset } = useForm();
  const [calculations, setCalculations] = useState<any>({});

  const navigatePage = useCallback((stage: string) => {
    if (user) {
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          stage: stage
        }, { merge: true });
      navigate(`/${stage}`);
    }
  }, [navigate, user, db])
  useEffect(() => {
    if (props.lastKnownTriage) {
      if (props.storedData?.triages[props.lastKnownTriage].keyRatio) {
        let { totalCashSale, netProfit, currentAssets, costOfGoodsSold, totalInventory, currentLiabilities,
          accountsReceivables, fixedAssets, fixedLiabilities, totalCreditSale } = props.storedData?.triages[props.lastKnownTriage].answers.KeyRatioAnalysis;
        let gmr = (removeCommas(totalCashSale) - removeCommas(costOfGoodsSold)) / removeCommas(totalCashSale);
        let npm = removeCommas(netProfit) / removeCommas(totalCashSale);
        let at = (removeCommas(currentAssets) - removeCommas(totalInventory)) / removeCommas(currentLiabilities);
        let wc = (removeCommas(currentAssets) - removeCommas(currentLiabilities));
        let roa = (removeCommas(netProfit) / removeCommas(currentAssets));
        let dr = (removeCommas(fixedLiabilities) + removeCommas(currentLiabilities)) / (removeCommas(fixedAssets) + removeCommas(currentAssets));
        let art = (removeCommas(totalCreditSale) / removeCommas(accountsReceivables));
        setCalculations({
          gmr: gmr, npm: npm, at: at, wc: wc, roa: roa, dr: dr, art: art
        })
      }
      else {
        CustomToast("error", "Please fill in the Financial Ratio Analysis section first to see all results");
        navigatePage(`${props.lastKnownTriage}/key-ratio-analysis`)
      }
    }
  }, [props, reset, props.lastKnownTriage, navigatePage])
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (<div className="card" id="card-keyanalysis-results" key={"keyanalaysisResults"}>
    <div className="card-body text-center px-4 pt-5 pt-md-5">
      <p className="fs-3 fw-bold text-primary card-text mb-2 title-color">Financial Ratio Analysis Results</p>
      <b>{props?.storedData?.triages[props.lastKnownTriage]?.date ?
        `As at: ${props?.storedData?.triages[props.lastKnownTriage]?.date?.toDate().toLocaleString("en-UK", { dateStyle: "medium", timeStyle: "medium" }).toString()}`
        : ""}</b>
      <br />
      <img src={FinanceAnime} width="200px" alt="Finance Animation" />
      <div>
        <form className="p-3 p-xl-4" method="post">
          <table className="table text-start">
            <thead>
              <tr>
                <th scope="col">Category</th>
                <th scope="col">Score</th>
                <th scope="col" className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Gross Margin Ratio</th>
                <td className={`text-center ${calculations.gmr < 40 ? "table-danger-custom" : calculations.gmr < 70 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.gmr?.toFixed(2)}%</td>
                <td>{calculations.gmr < 40 ? "Poor" : calculations.gmr < 70 ? "Fair" : "Excellent"} Operating Efficiency</td>
              </tr>
              <tr>
                <th scope="row">Net Profit Margin</th>
                <td className={`text-center ${calculations.npm < 15 ? "table-danger-custom" : calculations.npm < 30 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.npm?.toFixed(2)}%</td>
                <td>{calculations.npm < 15 ? "Poor" : calculations.npm < 30 ? "Fair" : "Excellent"} Profitability Status</td>
              </tr>
              <tr>
                <th scope="row">The Acid Test</th>
                <td className={`text-center ${calculations.at < 0.5 ? "table-danger-custom" : calculations.at < 1 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.at?.toFixed(2)}%</td>
                <td>{calculations.at < 0.5 ? "Poor" : calculations.at < 1 ? "Fair" : "Excellent"} Liquidity</td>
              </tr>
              <tr>
                <th scope="row">Working Capital</th>
                <td className={`text-center ${calculations.wc < 1 ? "table-danger-custom" : calculations.wc < 1.5 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.wc?.toFixed(0)}</td>
                <td>{calculations.wc < 1 ? "Poor" : calculations.wc < 1.5 ? "Fair" : "Excellent"} Liquidity Level</td>
              </tr>
              <tr>
                <th scope="row">Return on Assets(ROA)</th>
                <td className={`text-center ${calculations.roa < 10 ? "table-danger-custom" : calculations.roa < 20 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.roa?.toFixed(2)}</td>
                <td>{calculations.roa < 10 ? "Low" : calculations.roa < 20 ? "Average" : "High"} Efficient Use of Assets to generate Profit</td>
              </tr>
              <tr>
                <th scope="row">Debt Ratio</th>
                <td className={`text-center ${calculations.dr < 0.4 ? "table-danger-custom" : calculations.dr < 0.6 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.dr?.toFixed(2)}</td>
                <td>{calculations.dr < 0.4 ? "Lowly" : calculations.dr < 0.6 ? "Average" : "Highly"} Leveraged & Risky</td>
              </tr>
              <tr>
                <th scope="row">AR Turnover</th>
                <td className={`text-center ${calculations.art < 6 ? "table-danger-custom" : calculations.art < 12 ? "table-warning-custom" : "table-success-custom"}`}>{calculations.art?.toFixed(2)}</td>
                <td>{calculations.art < 6 ? "Inefficient" : calculations.art < 12 ? "Average" : "Efficient"} Debt Collection Process</td>
              </tr>
            </tbody>
          </table>
          <div className="mb-3" />
          <div>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    navigatePage(`${props.lastKnownTriage}/key-ratio-analysis`)
                  }
                  }>
                  <svg className="bi bi-arrow-left" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                  </svg>&nbsp;Back </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    navigatePage(`${props.lastKnownTriage}/marketing/1`)
                  }}
                >
                  Next&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-arrow-right"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </button>
              </div>

            </div>

          </div>
        </form>
      </div>
      <hr />
    </div>
    <CallToAction />
  </div>
  )
}
export const removeCommas = (answer: string) => {
  return Number(answer.replace(/[^0-9]/g, ""))
}

export default KeyRatioAnalysisResults;
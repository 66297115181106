import { useCallback, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ResponsivePie } from "@nivo/pie";
import { MarketingData } from "../datasource/MarketingData";
import { CustomToast } from "../ReusableComponents/CustomToast";
import { getColor, getRank, getRemark } from "./FinanceAnalysis";
import React from "react";
import CallToAction from "../ReusableComponents/CallToAction";

const MarketingAnalysis = (props: any) => {
  let navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const user: any = auth.currentUser;
  const { reset } = useForm();
  const [showdata, setShowdata] = useState<any>([]);
  const [showMarketingTotal, setShowMarketingTotal] = useState(0);
  const [results, setResults] = useState<any>([]);
  const navigatePage = useCallback((stage: string) => {
    if (user) {
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          stage: stage
        }, { merge: true });
      navigate(`/${stage}`);
    }
  }, [navigate, user, db]);
  useEffect(() => {
    if (props.lastKnownTriage) {
      if (props.storedData?.triages[props.lastKnownTriage].marketing) {
        let marketing = props.storedData?.triages[props.lastKnownTriage].answers.marketing;
        calculateResults(marketing);
        let total = 0;
        let objects = 0;
        Object.keys(marketing).map((key: string) => {
          if (marketing[key] !== "N/A") {
            total += Number(marketing[key]);
            objects++;
          }
          return ("");
        });
        total = (total / (objects * 90)) * 100;
        setShowMarketingTotal(total);
        setShowdata([
          {
            "id": "Marketing",
            "label": "Marketing",
            "value": total.toFixed(0),
            "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
          },
          {
            "id": "Areas to Improve",
            "label": "Areas to Improve",
            "color": "white",
            "value": (100 - total).toFixed(0),
          }
        ]);
      }
      else {
        CustomToast("error", "Please fill in the Marketing & Strategy section first to see all results");
        navigatePage(`${props.lastKnownTriage}/marketing/1`)
      }
    }
  }, [props, reset, navigatePage]);
  const calculateResults = (data: any) => {
    let dataDefaults = [
      {
        name: "Marketing Strategy",
        show: data[1],
        color: getColor(Number(data[1])),
        value: getRemark(Number(data[1])),
        rank: getRank(Number(data[1])),
      },
      {
        name: "Lead Generation",
        show: data[2],
        color: getColor(Number(data[2])),
        value: getRemark(Number(data[2])),
        rank: getRank(Number(data[2]))
      },
      {
        name: "Business Funnel Visibility",
        show: data[3],
        color: getColor(Number(data[3])),
        value: getRemark(Number(data[3])),
        rank: getRank(Number(data[3]))
      },
      {
        name: "Target Market Visibility",
        show: data[4] || data[5] || data[11] ? true : false,
        color: getColor((Number(data[4] + data[5] + data[11])) / 3),
        value: getRemark((Number(data[4] + data[5] + data[11])) / 3),
        rank: getRank((Number(data[4] + data[5] + data[11])) / 3),
      },
      {
        name: "Customer Satisfaction",
        show: data[6] || data[7] ? true : false,
        color: getColor((Number(data[7] + data[6])) / 2),
        value: getRemark((Number(data[7] + data[6])) / 2),
        rank: getRank((Number(data[7] + data[6])) / 2),
      },
      {
        name: "Product Advertisement",
        show: data[8],
        color: getColor(Number(data[8])),
        value: getRemark(Number(data[8])),
        rank: getRank(Number(data[8]))
      },
      {
        name: "Value Proposition",
        show: data[9] || data[10] ? true : false,
        color: getColor((Number(data[9] + data[10])) / 2),
        value: getRemark((Number(data[9] + data[10])) / 2),
        rank: getRank((Number(data[9] + data[10])) / 2),
      },
      {
        name: "Product/Service Promotion",
        show: data[12],
        color: getColor(Number(data[12])),
        value: getRemark(Number(data[12])),
        rank: getRank(Number(data[12]))
      },
      {
        name: "Sales Process",
        show: data[13],
        color: getColor(Number(data[13])),
        value: getRemark(Number(data[13])),
        rank: getRank(Number(data[13]))
      },
      {
        name: "Return on Investment(ROI)",
        show: data[14],
        color: getColor(Number(data[14])),
        value: getRemark(Number(data[14])),
        rank: getRank(Number(data[14]))
      },
      {
        name: "Pricing Strategy",
        show: data[15],
        color: getColor(Number(data[15])),
        value: getRemark(Number(data[15])),
        rank: getRank(Number(data[15]))
      },
      {
        name: "New Product Development",
        show: data[16],
        color: getColor(Number(data[16])),
        value: getRemark(Number(data[16])),
        rank: getRank(Number(data[16]))
      },
    ];
    setResults(dataDefaults);
  }
  const CenteredMetric = ({ centerX, centerY }: any) => {

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '40px',
          fontWeight: 600,
        }}
      >
        {showMarketingTotal.toFixed(0)}%
      </text>
    )
  }
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (<div className="card" id="card-management" key={"marketing-report"}>
    <div className="card-body text-center px-4 pt-5 pt-md-5">
      <p className="fs-3 fw-bold text-primary card-text mb-2 title-color">Marketing & Strategy Report</p>
      <b>{props?.storedData?.triages[props.lastKnownTriage]?.date ?
        `As at: ${props?.storedData?.triages[props.lastKnownTriage]?.date?.toDate().toLocaleString("en-UK", { dateStyle: "medium", timeStyle: "medium" }).toString()}`
        : ""}</b>
      <div style={{ height: 300 }}>
        {showdata ? <ResponsivePie
          borderColor={"#0000000"}
          colors={{ datum: 'data.color' }}
          data={showdata}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.85}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          layers={['arcs', CenteredMetric]}
        /> : <></>}
      </div>
      <div>
        <form className="p-3 p-xl-4" method="post">
          <div className="text-start">
            <table className="table text-start">
              <thead>
                <tr>
                  <th scope="col">Category</th>
                  <th scope="col" className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {results.sort((a: any, b: any) => a.rank - b.rank)
                  .map((data: any, index: any) => {
                    if (data.show) {
                      return (
                        <React.Fragment key={index}>
                          {index !== 0 && (results[index - 1]?.value) !== results[index]?.value ?
                            <tr>
                              <th scope="row"></th>
                              <td></td>
                            </tr> : <></>}
                          <tr key={index}>
                            <th scope="row">{data.name}</th>
                            <td className={`text-center ${data.color}`}>{data.value}</td>
                          </tr>
                        </React.Fragment>
                      )
                    }
                    else {
                      return (<></>)
                    }
                  })}
              </tbody>
            </table>
          </div>
          <div className="mb-3" />
          <div>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    navigatePage(`${props.lastKnownTriage}/marketing/${MarketingData.length}`)
                  }
                  }>
                  <svg className="bi bi-arrow-left" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                  </svg>&nbsp;Back </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    navigatePage(`${props.lastKnownTriage}/digital/1`)
                  }}
                >
                  Next&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-arrow-right"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </button>
              </div>

            </div>

          </div>
        </form>
      </div>
      <hr />
    </div >
    <CallToAction />
  </div >
  )
}

export default MarketingAnalysis;
import { useCallback, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { financeData } from "../datasource/FinanceData";
import { useForm } from "react-hook-form";
import { ResponsivePie } from "@nivo/pie";
import { CustomToast } from "../ReusableComponents/CustomToast";
import React from "react";
import CallToAction from "../ReusableComponents/CallToAction";

const FinanceAnalysis = (props: any) => {
  let navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const user: any = auth.currentUser;
  const { reset } = useForm();
  const [showdata, setShowdata] = useState<any>([]);
  const [showFinanceTotal, setShowFinanceTotal] = useState(0);
  const [results, setResults] = useState<any>([]);
  const navigatePage = useCallback((stage: string) => {
    if (user) {
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          stage: stage
        }, { merge: true });
      navigate(`/${stage}`);
    }
  }, [db, navigate, user]);
  const calculateResults = (data: any) => {
    let dataDefaults = [
      {
        name: "Costing and Pricing",
        show: data[1] || data[2] ? true : false,
        color: getColor((Number(data[1] + data[2]))),
        value: getRemark((Number(data[1] + data[2]) / 2)),
        rank: getRank((Number(data[1] + data[2]) / 2)),
      },
      {
        name: "Break-even Analysis",
        show: data[3],
        color: getColor(Number(data[3])),
        value: getRemark(Number(data[3])),
        rank: getRank(Number(data[3]))
      },
      {
        name: "Financial Planning",
        show: data[4],
        color: getColor(Number(data[4])),
        value: getRemark(Number(data[4])),
        rank: getRank(Number(data[4]))
      },
      {
        name: "Disaster Preparedness",
        show: data[5],
        color: getColor(Number(data[5])),
        value: getRemark(Number(data[5])),
        rank: getRank(Number(data[5]))
      },
      {
        name: "Financial Controls",
        show: data[6],
        color: getColor((Number(data[6][0]) + Number(data[6][1]) + Number(data[6][2]) + Number(data[6][3]) + Number(data[6][4]) + Number(data[6][5]) + Number(data[6][6]) + Number(data[6][7]) + Number(data[6][8])) / 9),
        value: getRemark((Number(data[6][0]) + Number(data[6][1]) + Number(data[6][2]) + Number(data[6][3]) + Number(data[6][4]) + Number(data[6][5]) + Number(data[6][6]) + Number(data[6][7]) + Number(data[6][8])) / 9),
        rank: getRank((Number(data[6][0]) + Number(data[6][1]) + Number(data[6][2]) + Number(data[6][3]) + Number(data[6][4]) + Number(data[6][5]) + Number(data[6][6]) + Number(data[6][7]) + Number(data[6][8])) / 9),
      },
      {
        name: "Working Capital Management",
        show: data[7] || data[18] ? true : false,
        color: getColor((Number(data[7] + data[18]) / 2)),
        value: getRemark((Number(data[7] + data[18]) / 2)),
        rank: getRank((Number(data[7] + data[18]) / 2)),
      },
      {
        name: "Accounting System",
        show: data[8],
        color: getColor(Number(data[8])),
        value: getRemark(Number(data[8])),
        rank: getRank(Number(data[8]))
      },
      {
        name: "Procurement",
        show: data[9],
        color: getColor(Number(data[9])),
        value: getRemark(Number(data[9])),
        rank: getRank(Number(data[9]))
      },
      {
        name: "Cash Flow Management",
        show: data[10],
        color: getColor(Number(data[10])),
        value: getRemark(Number(data[10])),
        rank: getRank(Number(data[10]))
      },
      {
        name: "Financial Accounting",
        show: data[11] || data[12] || data[13] ? true : false,
        color: getColor((Number(data[11] + data[12] + data[13])) / 3),
        value: getRemark((Number(data[11] + data[12] + data[13])) / 3),
        rank: getRank((Number(data[11] + data[12] + data[13])) / 3),
      },
      {
        name: "Internal Audit",
        show: data[14],
        color: getColor(Number(data[14])),
        value: getRemark(Number(data[14])),
        rank: getRank(Number(data[14]))
      },
      {
        name: "Tax Compliance",
        show: data[15] || data[16] ? true : false,
        color: getColor((Number(data[15] + data[16])) / 2),
        value: getRemark((Number(data[15] + data[16])) / 2),
        rank: getRank((Number(data[15] + data[16])) / 2),
      },
      {
        name: "Financial Reporting",
        show: data[17],
        color: getColor(Number(data[17])),
        value: getRemark(Number(data[17])),
        rank: getRank(Number(data[17]))
      },
      {
        name: "Work Capital Management",
        show: data[19],
        color: getColor(Number(data[19])),
        value: getRemark(Number(data[19])),
        rank: getRank(Number(data[19])),
      },
      {
        name: "Business Model",
        show: data[20],
        color: getColor(Number(data[20])),
        value: getRemark(Number(data[20])),
        rank: getRank(Number(data[20])),
      },
      {
        name: "Business Stability",
        show: data[21],
        color: getColor(Number(data[21])),
        value: getRemark(Number(data[21])),
        rank: getRank(Number(data[21])),
      },
      {
        name: "Credit Control",
        show: data[22],
        color: getColor(Number(data[22])),
        value: getRemark(Number(data[22])),
        rank: getRank(Number(data[22]))
      },
      {
        name: "Margin Awareness",
        show: data[23],
        color: getColor(Number(data[23])),
        value: getRemark(Number(data[23])),
        rank: getRank(Number(data[23]))
      },
    ];
    setResults(dataDefaults);
  }
  const CenteredMetric = ({ centerX, centerY }: any) => {

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '40px',
          fontWeight: 600,
        }}
      >
        {showFinanceTotal.toFixed(0)}%
      </text>
    )
  }
  useEffect(() => {
    if (props.lastKnownTriage) {
      if (props.storedData?.triages[props.lastKnownTriage].finance) {
        let finance = props.storedData?.triages[props.lastKnownTriage].answers.finance;
        calculateResults(finance);
        let total = 0;
        let objects = 0;
        Object.keys(finance).map((key: string) => {
          if (finance[key] !== "N/A") {
            if (finance[key] instanceof Object) {
              Object.keys(finance[key]).map((key2: string) => {
                if (finance[key][key2] !== "N/A") {
                  total += Number(finance[key][key2]);
                  objects++;
                }
                return ("");
              })
            }
            else {
              total += Number(finance[key]);
              objects++;
            }
          }
          return ("");
        });
        total = (total / (objects * 90)) * 100;
        setShowFinanceTotal(total);
        setShowdata([
          {
            "id": "Finance",
            "label": "Finance",
            "value": total.toFixed(0),
            "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
          },
          {
            "id": "Areas to Improve",
            "label": "Areas to Improve",
            "color": "white",
            "value": (100 - total).toFixed(0),
          }
        ]);
      }
      else {
        CustomToast("error", "Please fill in the Financial Management section first to see all results");
        navigatePage(`${props.lastKnownTriage}/finance/1`)
      }
    }
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [props, reset, navigatePage])
  return (<div className="card" id="card-finance" key={"finance-report"}>
    <div className="card-body text-center px-4 pt-5 pt-md-5">
      <p className="fs-3 fw-bold text-primary card-text mb-2 title-color">Financial Management Report</p>
      <b>{props?.storedData?.triages[props.lastKnownTriage]?.date ?
        `As at: ${props?.storedData?.triages[props.lastKnownTriage]?.date?.toDate().toLocaleString("en-UK", { dateStyle: "medium", timeStyle: "medium" }).toString()}`
        : ""}</b>
      <div style={{ height: 300 }}>
        {showdata ? <ResponsivePie
          borderColor={"#0000000"}
          colors={{ datum: 'data.color' }}
          data={showdata}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.85}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          layers={['arcs', CenteredMetric]}
        /> : <></>}
      </div>
      <div>
        <form className="p-3 p-xl-4" method="post">
          <div className="text-start">
            <table className="table text-start">
              <thead>
                <tr>
                  <th scope="col">Category</th>
                  <th scope="col" className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {results.sort((a: any, b: any) => a.rank - b.rank)
                  .map((data: any, index: any) => {
                    if (data.show) {
                      return (
                        <React.Fragment key={index}>
                          {index !== 0 && (results[index - 1]?.value) !== results[index]?.value ?
                            <tr>
                              <th scope="row"></th>
                              <td></td>
                            </tr> : <></>}
                          <tr key={index}>
                            <th scope="row">{data.name}</th>
                            <td className={`text-center ${data.color}`}>{data.value}</td>
                          </tr>
                        </React.Fragment>
                      )
                    }
                    else {
                      return (<></>)
                    }
                  })}
              </tbody>
            </table>
          </div>
          <div className="mb-3" />
          <div>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    navigatePage(`${props.lastKnownTriage}/finance/${financeData.length()}`)
                  }
                  }>
                  <svg className="bi bi-arrow-left" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                  </svg>&nbsp;Back </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-primary shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    navigatePage(`${props.lastKnownTriage}/key-ratio-analysis`)
                  }}
                >
                  Next&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-arrow-right"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </button>
              </div>

            </div>

          </div>
        </form>
      </div>
      <hr />
    </div >
    <CallToAction />

  </div >
  )
}
export const getColor = (value: number) => {
  if (value <= 5) {
    return "table-danger-custom";
  }
  else if (value <= 15) {
    return "table-warning-custom";
  }
  else {
    return "table-success-custom";
  }
}
export const getRemark = (value: number) => {
  if (value <= 5) {
    return "Weak";
  }
  else if (value <= 15) {
    return "Deficient";
  }
  else {
    return "Excellent";
  }
}
export const getRank = (value: number) => {
  if (value <= 5) {
    return 3;
  }
  else if (value <= 15) {
    return 2;
  }
  else {
    return 1;
  }
}
export default FinanceAnalysis;
import { useCallback, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from '@firebase/app';
import { firebaseConfig } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ResponsivePie } from "@nivo/pie";
import { DigitalData } from "../datasource/DigitalData";
import { CustomToast } from "../ReusableComponents/CustomToast";
import { getColor, getRank, getRemark } from "./FinanceAnalysis";
import React from "react";
import CallToAction from "../ReusableComponents/CallToAction";

const DigitalAnalysis = (props: any) => {
  let navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const user: any = auth.currentUser;
  const { reset } = useForm();
  const [showdata, setShowdata] = useState<any>([]);
  const [showFinanceTotal, setShowFinanceTotal] = useState(0);
  const [results, setResults] = useState<any>([]);
  const navigatePage = useCallback((stage: string) => {
    if (user) {
      const userRef = doc(db, 'users', user?.email);
      setDoc(userRef,
        {
          stage: stage
        }, { merge: true });
      navigate(`/${stage}`);
    }
  }, [db, navigate, user])
  const calculateResults = (data: any) => {
    let dataDefaults = [
      {
        name: "Digital Planning",
        show: data[1],
        color: getColor(Number(data[1])),
        value: getRemark(Number(data[1])),
        rank: getRank(Number(data[1])),
      },
      {
        name: "Social Media Presence",
        show: data[2],
        color: getColor((Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])) / 9),
        value: getRemark((Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])) / 9),
        rank: getRank((Number(data[2][0]) + Number(data[2][1]) + Number(data[2][2]) + Number(data[2][3]) + Number(data[2][4]) + Number(data[2][5]) + Number(data[2][6]) + Number(data[2][7]) + Number(data[2][8])) / 9),
      },
      {
        name: "Digital Marketing Plan",
        show: data[3],
        color: getColor(Number(data[3])),
        value: getRemark(Number(data[3])),
        rank: getRank(Number(data[3])),
      },
      {
        name: "Social Media KPIs",
        show: data[4],
        color: getColor(Number(data[4])),
        value: getRemark(Number(data[4])),
        rank: getRank(Number(data[4])),
      },
      {
        name: "Website Presence",
        show: data[5],
        color: getColor(Number(data[5])),
        value: getRemark(Number(data[5])),
        rank: getRank(Number(data[5])),
      },
      {
        name: "Corporate Page Visibility",
        show: data[6],
        color: getColor(Number(data[6])),
        value: getRemark(Number(data[6])),
        rank: getRank(Number(data[6])),
      },
      {
        name: "Digital Content Planning",
        show: data[7],
        color: getColor(Number(data[7])),
        value: getRemark(Number(data[7])),
        rank: getRank(Number(data[7])),
      },
      {
        name: "Content Creation Capability",
        show: data[8],
        color: getColor(Number(data[8])),
        value: getRemark(Number(data[8])),
        rank: getRank(Number(data[8])),
      },
      {
        name: "Website Responsiveness",
        show: data[9],
        color: getColor(Number(data[9])),
        value: getRemark(Number(data[9])),
        rank: getRank(Number(data[9])),
      },
      {
        name: "SEO Optimization",
        show: data[10],
        color: getColor(Number(data[10])),
        value: getRemark(Number(data[10])),
        rank: getRank(Number(data[10])),
      },
      {
        name: "Website Update Simplicity",
        show: data[11],
        color: getColor(Number(data[11])),
        value: getRemark(Number(data[11])),
        rank: getRank(Number(data[11])),
      },
      {
        name: "Buyer Journey Mapping",
        show: data[12] || data[13] ? true : false,
        color: getColor((Number(data[12] + data[13])) / 2),
        value: getRemark((Number(data[12] + data[13])) / 2),
        rank: getRank((Number(data[12] + data[13])) / 2),
      },
      {
        name: "Digital Audit",
        show: data[14],
        color: getColor(Number(data[14])),
        value: getRemark(Number(data[14])),
        rank: getRank(Number(data[14])),
      },
      {
        name: "Conversion Rate",
        show: data[15],
        color: getColor(Number(data[15])),
        value: getRemark(Number(data[15])),
        rank: getRank(Number(data[15])),
      },
    ];
    setResults(dataDefaults);
  }
  useEffect(() => {
    if (props.lastKnownTriage) {
      if (Object.entries(props.storedData?.triages[props.lastKnownTriage].answers.digital).length === 15) {
        let digital = props.storedData?.triages[props.lastKnownTriage].answers.digital;
        calculateResults(digital);
        let total = 0;
        let objects = 0;
        Object.keys(digital).map((key: string) => {
          if (digital[key] !== "N/A") {
            if (digital[key] instanceof Object) {
              Object.keys(digital[key]).map((key2: string) => {
                if (digital[key][key2] !== "N/A") {
                  total += Number(digital[key][key2]);
                  objects++;
                }
                return ("");
              })
            }
            else {
              total += Number(digital[key]);
              objects++;
            }
          }
          return ("");
        });
        total = (total / (objects * 90)) * 100;
        setShowFinanceTotal(total);
        setShowdata([
          {
            "id": "Digital",
            "label": "Digital",
            "value": total.toFixed(0),
            "color": `${total < 33 ? "rgb(255 0 0)" : total < 66 ? "rgb(255	255 0)" : "rgb(0	255	0	)"}`
          },
          {
            "id": "Areas to Improve",
            "label": "Areas to Improve",
            "color": "white",
            "value": (100 - total).toFixed(0),
          }
        ]);
      }
      else {
        CustomToast("error", "Please fill in the Digital Presence section first to see all results");
        navigatePage(`${props.lastKnownTriage}/digital/1`)
      }
    }
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [props, reset, navigatePage])
  const CenteredMetric = ({ centerX, centerY }: any) => {

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '40px',
          fontWeight: 600,
        }}
      >
        {showFinanceTotal.toFixed(0)}%
      </text>
    )
  }
  return (<div className="card" id="card-digital" key={"finance-report"}>
    <div className="card-body text-center px-4 pt-5 pt-md-5">
      <p className="fs-3 fw-bold text-primary card-text mb-2 title-color">Digital Presence Report</p>
      <b>{props?.storedData?.triages[props.lastKnownTriage]?.date ?
        `As at: ${props?.storedData?.triages[props.lastKnownTriage]?.date?.toDate().toLocaleString("en-UK", { dateStyle: "medium", timeStyle: "medium" }).toString()}`
        : ""}</b>
      <div style={{ height: 300 }}>
        {showdata ? <ResponsivePie
          borderColor={"#0000000"}
          colors={{ datum: 'data.color' }}
          data={showdata}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.85}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          layers={['arcs', CenteredMetric]}
        /> : <></>}
      </div>
      <div>
        <div className="text-start">
          <table className="table text-start">
            <thead>
              <tr>
                <th scope="col">Category</th>
                <th scope="col" className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {results.sort((a: any, b: any) => a.rank - b.rank)
                .map((data: any, index: any) => {
                  if (data.show) {
                    return (
                      <React.Fragment key={index}>
                        {index !== 0 && (results[index - 1]?.value) !== results[index]?.value ?
                          <tr>
                            <th scope="row"></th>
                            <td></td>
                          </tr> : <></>}
                        <tr key={index}>
                          <th scope="row">{data.name}</th>
                          <td className={`text-center ${data.color}`}>{data.value}</td>
                        </tr>
                      </React.Fragment>
                    )
                  }
                  else {
                    return (<></>)
                  }
                })}
            </tbody>
          </table>
        </div>
        <div className="mb-3" />
        <div>
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-primary shadow d-block w-100"
                type="button"
                onClick={() => {
                  navigatePage(`${props.lastKnownTriage}/digital/${DigitalData.length}`)
                }
                }>
                <svg className="bi bi-arrow-left" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                </svg>&nbsp;Back </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary shadow d-block w-100"
                type="button"
                onClick={() => {
                  navigatePage(`${props.lastKnownTriage}/operations/1`)
                }}
              >
                Next&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  className="bi bi-arrow-right"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </button>
            </div>

          </div>

        </div>
      </div>
      <hr />
    </div >
    <CallToAction />
  </div >
  )
}

export default DigitalAnalysis;